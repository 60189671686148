@import '../../styles/variables';

.Home {
  padding: 0 2em;
  background: $dark-gray-background;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &__header {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 0 0 10px 10px;
    margin-bottom: 3em;
    overflow: hidden;

    &Meteo {
      position: relative;
      background-position: center;
      background-size: cover;
      height: 6.5em;
      color: white;
      padding: 1.25em;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        color: #eac618;
        text-transform: uppercase;
        font-weight: bold;
      }

      & > div {
        height: 100%;
      }

      &Icon {
        height: 4em;
        position: absolute;
        top: 1.25em;
        right: calc(1.25em + 12em / 2);
        transform: translateX(50%);

        &Background {
          height: 12em;
          width: 12em;
          border-radius: 50%;
          background: white;
          opacity: 0.1;
          position: absolute;
          top: 0;
          transform: translateY(-50%);
          right: 1.25em;
        }
      }
    }
  }

  &__stats {
    display: flex;
    padding: 0 2em;

    &Card {
      flex-basis: 0;
      display: flex;
      align-items: center;
      flex-grow: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      box-shadow: $shadow;
      padding: 2em;
      background: white;
      border-radius: 10px;
      margin-right: 2em;

      &:last-child {
        margin-right: 0;
      }

      &--main {
        background: $accent-color;
        color: white;

        .Home__statsCardTitle {
          color: white;
        }
      }

      &--disabled {
        background: #c2c2c2;
        color: white;
        text-shadow: 1px 1px 2px rgba(190, 190, 190, 1);
      }

      &Title {
        font-size: 2em;
        font-weight: bold;
        color: $accent-color;

        span {
          font-size: 0.75em;
        }
      }
    }
  }

  &__separator {
    color: #8f8f8f;
    text-transform: uppercase;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 0 2em;
    margin: 5em 0 3em 0;

    &Bar {
      height: 1px;
      margin-left: 1em;
      flex-grow: 1;
      background-color: #c7c7c7;
    }

    i {
      text-transform: none;
      font-size: 2em;
    }
  }

  &__campaigns {
    display: grid;
    padding: 0 2em;
    grid-template-columns: repeat(auto-fit, minmax(25em, 1fr));
    grid-gap: 1em;
    justify-items: center;

    &Card {
      height: 20em;
      width: 25em;
      border-radius: 10px;
      background-color: white;
      position: relative;
      box-shadow: 0px 2.5px 5px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      transition: box-shadow 0.25s;

      &:hover {
        box-shadow: $shadow;
      }

      &:hover {
        .Home__campaignsCardButtons {
          display: flex;
        }
      }

      &Buttons {
        position: absolute;
        display: none;
        top: 0;
        right: 0;
        padding: 0.75em;

        a {
          text-decoration: none;
        }

        &Entry {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 0.25em;
          height: 1.25em;
          width: 1.25em;
          border-radius: 50%;
          background-color: #4a4a4a;
          color: white;
          cursor: pointer;

          &:last-child {
            margin-right: 0;
          }

          &--danger {
            background-color: $error-color;
          }
        }
      }

      &Header,
      &Stats {
        padding: 1em;
      }

      &Title {
        font-size: 1.25em;
        text-transform: uppercase;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &Subtitle {
        color: $dark-gray;
        margin-bottom: 1em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &Indicators {
        display: flex;

        &Entry {
          display: flex;
          margin-right: 1em;
          align-items: center;

          &:last-child {
            margin-right: 0;
          }

          &Icon {
            font-size: 1.1em;
            margin-right: 0.25em;
          }

          &Label {
            font-size: 0.9em;
          }
        }
      }

      &Separator {
        height: 1px;
        width: 100%;
        background-color: #ececec;
      }

      &Stats {
        &Entry {
          display: flex;
          margin-bottom: 2em;

          &:last-child {
            margin-bottom: 0;
          }

          &Percentage {
            margin-right: 0.75em;

            &Body {
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 10px;
              background-color: $secondary-color;
              color: white;
              font-weight: bold;
              height: 3em;
              width: 3em;

              &--accent {
                background-color: $accent-color;
              }

              span {
                font-size: 0.75em;
              }
            }
          }

          &Details {
            flex-grow: 1;

            &Title {
              font-size: 0.9em;
              margin-bottom: 0.5em;

              span {
                font-size: 0.75em;
              }
            }

            &Body {
              font-size: 0.8em;

              &Subtitle {
                color: $dark-gray;
              }

              &Progress {
                display: flex;
                flex-grow: 1;
                justify-content: space-between;
                margin-bottom: 0.25em;

                &:last-child {
                  margin-bottom: 0;
                }

                &Bar {
                  display: flex;
                  align-items: center;

                  &Body {
                    width: 10em;
                    height: 5px;
                    border-radius: 5px;
                    background-color: $dark-gray-background;
                    margin-right: 0.25em;
                    overflow: hidden;

                    &Fill {
                      height: 100%;
                      background-color: $accent-color;
                    }
                  }

                  &Label {
                    width: 3em;
                    text-align: end;
                    font-weight: bold;
                  }
                }
              }
            }
          }
        }
      }
    }

    &Add {
      background-color: #ececec;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: $secondary-color;
      border: 0.25em dashed $secondary-color;
      box-shadow: none;
      flex-shrink: 0;

      &Icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $secondary-color;
        border-radius: 50%;
        font-size: 2.5em;
        height: 1.25em;
        width: 1.25em;
        color: white;
        margin-bottom: 0.5em;
      }

      &Title {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.25em;
        text-align: center;
      }
    }
  }
}
