@import '../../../styles/variables';

.CampaignCreationTitle {
  .Button {
    margin-top: 1em;
    background-color: $accent-color;
    color: white;

    &--disabled {
      background-color: $light-gray;
      color: white;
      cursor: not-allowed;
    }
  }
}
