@import "../../styles/variables";

.layout {
    background: $dark-gray-background;
    width: 100%;
    min-height: 100%;
}

.edit-container {
    display: flex;
    justify-content: space-between;
}

.edit {
    display: flex;
    padding: 0.5rem;
    justify-content: flex-end;
    border: none;
    background-color: transparent;

    :hover {
        cursor: pointer;
    }

    &__icon {
        height: 1.5rem;
        width: auto;
        visibility: visible;
    }

    &__cancel {
        position: absolute;
        height: 1.5rem;
        width: auto;
        visibility: hidden;

        path:nth-child(1) {
            fill: red;
        }
    }
}

.header {
    display: flex;
    align-items: center;
    padding: 0rem 1rem;
    display: none;
}

.react-datepicker-wrapper {
    width: auto !important;
    margin: 0 0 0 1rem;
}

.card {
    height: 25em;
    width: calc(50% - 1em);
    border-radius: 5px;
    background-color: white;
    position: relative;
    margin-right: 2em;
    margin-bottom: 2em;
    box-shadow: 0px 2.5px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    &:hover {
        box-shadow: $shadow;
    }
}

.button-layout {
    text-transform: capitalize;
    background: #0c62ff;
    cursor: pointer;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid gray;
    color: #dcdada;
    margin: 10px 40%;
}

.remove-card {
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    border: none;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: black;

    &:hover {
        color: red;
    }
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropbtn {
    min-width: 5rem;
    min-height: 1.4rem;
    border-radius: 6px;
    background-color: #3498db;
    color: white;
    font-size: 13px;
    border: none;
    cursor: pointer;

    &:hover {
        background-color: #2980b9;
    }
}

.dropdownContent {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: white;
    overflow: hidden;
    transition: ease-out 0.2s;
    border-radius: 0px 10px 10px 10px;
    width: 21em;
    height: auto;
    padding: 1em;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.inputNameContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.typeSelectorContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.themeSelectorContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nameInputContainer {
    width: 14rem;
}

.typeInputContainer {
    width: 14rem;
}

.themeInputContainer {
    width: 14rem;
}

.widgetButtonContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.addItem {
    border-radius: 6px;
    background-color: #5cdb5c;
    color: white;
    padding: 8px;
    font-size: 16px;
    border: none;
    cursor: pointer;

    &:hover,
    &:focus {
        background-color: #4cae4c;
    }
}

.addItemDisabled {
    border-radius: 6px;
    background-color: #dcdcdc;
    color: white;
    padding: 8px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.tabsContainer {
    padding: 30px;
    height: 500px;
}

.labelsContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    :first-child {
        font-size: 32px;
    }
}

.addWidgetContainer {
    display: flex;
    flex-direction: column;
    height: 90vh;
    align-items: center;
    justify-content: center;
}

.pdfButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7em;
    height: 2em;
    background-color: white;
    margin-top: 1em;
    margin-left: 1em;
    margin-bottom: 1em;
    border-radius: 0.5em;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);

    &:hover {
        cursor: pointer;
        box-shadow: $shadow
    }
}

.color-card {
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    border: none;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: black;

}

.colorChanger {
    height: 0;
    width: 0;
    position: absolute;
    top: 0.5em;
    left: 2em;

}

.buttonValidateColor {
    width: 45px;
    height: 45px;
    position: absolute;
    left: -0.2em;
    bottom: 0;
    background: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: rgb(94, 94, 94);

    &:hover {
        color: #4cae4c;
    }
}