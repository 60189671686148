@import '../../styles/variables';

.Login {
  display: flex;
  height: 100%;
  text-align: center;

  &__right {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    color: white;
    padding: 5em;

    &Background {
      height: 100%;
      width: 100%;
      object-fit: cover;
      flex-grow: 0;
      position: absolute;
      z-index: -1;
    }

    &Overlay {
      position: absolute;
      z-index: -1;
      background: linear-gradient(90deg, #2776d8 0%, #003977 100%);
      opacity: 75%;
      height: 100%;
      width: 100%;
    }

    &Logo {
      height: 5em;
      margin-bottom: 3em;
    }
  }

  &__left {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    padding: 10em 10em;

    form {
      display: flex;
      flex-direction: column;

      .LoginInput {
        margin-bottom: 1em;
      }

      input[type='submit'] {
        margin-top: 0.5em;
        height: 2em;
        margin-bottom: 0.5em;
        background: linear-gradient(
          90deg,
          #2776d8 0%,
          #1a6acc 50%,
          #003977 100%
        );
        border: none;
        color: white;
        text-transform: uppercase;
        font-size: 2em;
        font-weight: bold;
        box-shadow: 7px 7px 10px 0px rgba(0, 0, 0, 0.25);
        cursor: pointer;
      }
    }

    &Welcome {
      text-transform: uppercase;
      font-size: 1.25em;
      margin-bottom: 1em;
    }

    &Logo {
      margin-bottom: 3em;
      height: 5em;
      align-self: center;
    }

    &Hint {
      margin-bottom: 2em;
    }

    &ForgottenPassword {
      color: $secondary-color;
      text-decoration: none;
    }
  }

  @media (max-width: 1000px) {
    &__left {
      width: 100%;
    }

    &__right {
      width: 0;
    }
  }
}
