@import '../../styles/variables';

.ListForm {
  padding: 3em 5em;

  .Button {
    margin-top: 2em;
    color: white;
    background-color: $accent-color;

    &--disabled {
      background-color: $light-gray;
      color: white;
      cursor: not-allowed;
    }
  }

  .InputWithLabel {
    margin-bottom: 1em;
  }

  input[type='file'] {
    margin-bottom: 0.5em;
  }

  &__hint {
    font-size: 0.8em;
    color: $dark-gray;

    a {
      color: unset;

      &:visited {
        color: unset;
      }
    }
  }
}
