@import '../../styles/variables';

.Layout__content > .MyAccount {
  padding-top: 7em;
}

.MyAccount {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3em;
  background-color: $light-gray;

  &__header {
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1em;
  }

  &__content {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    padding: 5em 2em;
    width: 100%;
    max-width: 75em;
    flex-grow: 1;

    &Left {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;

      &Picture {
        width: 15em;
        height: 15em;
        border-radius: 50%;
        overflow: hidden;
        margin-bottom: 1em;
        position: relative;

        &:hover {
          .MyAccount__contentLeftPictureHover {
            display: flex;
          }
        }

        &Hover {
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          display: none;
          background: rgba(0, 0, 0, 0.5);
          cursor: pointer;
          position: absolute;
          top: 0;

          i {
            color: white;
            font-size: 5em;
          }
        }

        input[type='file'] {
          display: none;
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      &Name {
        font-size: 2em;
      }
    }

    &Right {
      width: 50%;

      .InputWithLabel {
        margin-bottom: 1.5em;
        width: 100%;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    margin-top: 1em;

    .Button {
      margin-right: 1em;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media (max-width: 1000px) {
    &__content {
      flex-direction: column;
      padding: 2em;

      &Left {
        &Name {
          margin-bottom: 2em;
        }
      }

      &Left,
      &Right {
        width: 100%;
      }
    }
  }
}
