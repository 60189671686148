@import '../../../styles/variables';

.Layout__menu {
  padding: 1.5em;
  position: fixed;
  z-index: 1;
  background-color: white;
  font-size: 0.9em;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 17.5em;
  box-shadow: 5px 0px 5px 0px rgba(0, 0, 0, 0.1);
  color: $dark-gray;

  a {
    text-decoration: none;
    color: unset;

    &:visited {
      color: unset;
    }
  }

  &Mobile {
    display: none;
  }

  &Logo {
    max-width: 100%;
    max-height: 3em;
  }

  nav {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &Toggle {
    display: none;
  }

  &Header {
    display: flex;
  }

  &Entry {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 2em;
    text-decoration: none;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: $secondary-color;

      svg {
        path {
          fill: $secondary-color;
        }
      }
    }

    svg {
      width: 1.5em;
      height: auto;
      margin-right: 1em;

      path {
        fill: $dark-gray;
      }
    }
  }

  &Campaigns {
    margin-top: 0.5em;

    &Entry {
      margin-left: 3em;
      padding-left: 0.5em;
      border-left: 1px solid $dark-gray;
      cursor: pointer;

      &--selected {
        color: $secondary-color;
      }
    }
  }

  &Profile {
    margin-bottom: 1em;

    &Picture {
      display: flex;
      flex-direction: column;

      &Content {
        height: 5em;
        width: 5em;
        object-fit: cover;
        border-radius: 50%;
        transform: translateY(1em);

        &Wrapper {
          margin-bottom: 2px;
          overflow: hidden;
        }
      }

      &Points {
        position: relative;
        display: flex;
        justify-content: center;
        width: 6.5em;
        font-size: 0.75em;
        color: white;
        font-weight: bold;
        background-color: $secondary-color;
        padding: 0.25em 0.5em;
        border-radius: 3px;
      }

      &Lang {
        position: absolute;
        width: 2em;
        height: 2em;
        right: 0;
        top: 0;
        transform: translateX(50%) translateY(-50%);
        border: 2px solid white;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;

        img {
          height: 100%;
          width: 100%;
        }

        &Hover {
          opacity: 0;
          display: flex;
          position: absolute;
          top: 0;
          height: 100%;
          width: 100%;
          justify-content: center;
          align-items: flex-end;
          background-color: rgba(0, 0, 0, 0.25);
          font-size: 1.5em;
          transition: opacity 0.1s;

          &:hover {
            opacity: 1;
          }
        }

        &Content {
          display: flex;
          flex-direction: column;
          position: absolute;
          right: 0;
          top: 1.5em;
          transform: translateX(50%);
          padding: 0.5em;
          background-color: rgba(0, 0, 0, 0.75);
          border-radius: 5px;

          &Entry {
            border-radius: 50%;
            overflow: hidden;
            cursor: pointer;
            width: 2em;
            height: 2em;
            margin-bottom: 0.5em;
            border: 2px solid white;

            &:last-child {
              margin-bottom: 0;
            }

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  &Infos {
    &Name {
      font-weight: bold;
      margin-bottom: 0.25em;
      color: $primary-color;
    }

    &Rank {
      font-size: 0.75em;
      font-weight: bold;

      span {
        font-size: 1.25em;
        color: $secondary-color;
      }

      a {
        color: $secondary-color;
        text-decoration: none;
      }
    }
  }

  &Bottom {
    .Button {
      margin-top: 2em;
    }
  }

  @media (max-width: 1000px) {
    $mobile-menu-height: 5em;

    display: none;

    &Logo {
      flex-shrink: 1;
      margin-bottom: 0;
      height: 50%;
      margin: 0 2em;
    }

    &Mobile {
      position: fixed;
      display: flex;
      z-index: 1;
      flex-direction: column;
      width: 100%;
      background-color: white;
      padding: 0 1.5em;

      &--expanded {
        height: 100%;
      }

      &Previous {
        display: flex;
        align-items: center;
        cursor: pointer;

        i {
          margin-right: 0.25em;
        }
      }

      &Quit {
        color: $error-color;
        text-transform: uppercase;
        font-weight: bold;
        cursor: pointer;
      }

      &Header {
        height: $mobile-menu-height;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
      }

      &Toggle {
        display: flex;
        align-items: center;
        font-size: 2.5em;
        margin-right: 1em;
        cursor: pointer;
      }

      &Content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
        padding: 3em;

        .Layout__menuEntry {
          font-size: 1.5em;
          display: flex;
          justify-content: center;

          svg {
            display: none;
          }
        }

        .Layout__menuBottom,
        .Layout__menuInfo {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
        }
      }
    }
  }
}

.Layout__menuTopHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 6em;
}

.didactitiel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
}

.didactitielButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  width: 3em;
  background-color: rgb(252, 252, 252);
  box-shadow: 0px 2.5px 5px rgba(0, 0, 0, 0.1);
  color: rgb(92, 92, 92);
  border-radius: 5px;

  &:hover {
    box-shadow: $shadow;
    cursor: pointer;
  }
}