@import '../../styles/variables';

.CampaignCreation {
  min-height: 100%;
  padding: 5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $dark-gray-background;

  .stepper {
    box-shadow: none;
    border: none;
    width: 100%;
    min-width: 30em;
    max-width: 75em;
    background-color: transparent;

    section:empty {
      display: none;
    }

    section {
      background-color: white;
      border-radius: 10px;
      overflow: hidden;

      .section {
        &__title {
          display: flex;
          justify-content: center;
          background-color: $accent-color;
          color: white;
          padding: 1em;
          font-size: 1.25em;
          text-transform: uppercase;
        }

        &__body {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 3em 5em;
        }
      }
    }

    &__header {
      box-shadow: none;
      width: 70%;
      margin: 0 auto;
      margin-bottom: 1em;
    }

    &__head {
      display: flex;
      flex-direction: column;

      &:not(.stepper__head--disabled):hover {
        background-color: transparent;
      }

      &__index {
        background-color: white;
        margin: 5px;
      }

      &--active {
        .stepper__head__index {
          background-color: $accent-color;
          color: white;
        }

        .stepper__head__title {
          color: $accent-color;
        }
      }
    }

    .InputWithLabel {
      margin-bottom: 1em;
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
