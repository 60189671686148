@import '../../styles/variables';

.CampaignTemplates {
  padding: 5em 3em;
  background: $dark-gray-background;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &__adminLink {
    align-self: center;
    text-decoration: none;
    margin-bottom: 5em;
  }

  &__body {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-gap: 2em;
    grid-template-columns: repeat(auto-fit, minmax(25em, 1fr));
  }

  &__template {
    height: 100%;
    width: 25em;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    &Body {
      display: flex;
      flex-direction: column;
      padding: 1.5em;
      flex-grow: 1;
    }

    span {
      color: $secondary-color;
    }

    &Image {
      height: 12.5em;
      object-fit: cover;
    }

    &Title,
    &Subtitle {
      color: $accent-color;
      text-transform: uppercase;
    }

    &Title {
      font-size: 1.25em;
      margin-bottom: 0.25em;
    }

    &Subtitle {
      margin-bottom: 1em;
    }

    &Separator {
      height: 1px;
      margin: 1em 0;
      width: 100%;
      background: $dark-gray-background;
    }

    &Footer {
      font-size: 0.8em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;

      &Contents {
        display: flex;

        &Separator {
          margin: 0 0.5em;
        }
      }
    }

    .Button {
      margin-top: 2em;
    }
  }
}
