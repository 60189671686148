@import '../../../styles/variables';

.CampaignCreationSchedule {
  .section__actions {
    .Button {
      background-color: $accent-color;
      color: white;
      margin-top: 1em;

      &--disabled {
        background-color: $light-gray;
        color: white;
        cursor: not-allowed;
      }
    }
  }

  &__title {
    color: #7f7f7f;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0.5em;
  }

  .react-calendar {
    border: none;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);

    .react-calendar__tile--now,
    .react-calendar__tile--now:focus,
    .react-calendar__tile--now:active {
      background-color: white;
    }

    .react-calendar__tile--now {
      &:enabled {
        background-color: white;
      }

      &:hover {
        background-color: #e6e6e6;
      }
    }

    .react-calendar__tile--active,
    .react-calendar__tile--active:enabled,
    .react-calendar__tile--active:hover,
    .react-calendar__tile--active:active,
    .react-calendar__tile--hasActive,
    .react-calendar__tile--hasActive:enabled,
    .react-calendar__tile--hasActive:hover,
    .react-calendar__tile--hasActive:focus,
    .react-calendar__tile--hasActive:active {
      background-color: $accent-color;
      color: white;
    }
  }

  &__schedule {
    display: flex;
    margin-bottom: 3em;
  }

  &__dates {
    margin-right: 3em;
  }

  &__hours {
    &Start {
      margin-bottom: 3em;
    }

    .react-timekeeper {
      width: 10em;
    }
  }

  &__recap {
    margin-bottom: 3em;
  }

  &__startingEmail {
    margin-bottom: 3em;

    .CampaignCreationSchedule__title {
      text-align: center;
    }

    &Hint {
      color: $dark-gray;
      font-size: 0.75em;
    }
  }
}
