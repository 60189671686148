@import '../../styles/variables';

.Button {
  box-shadow: $shadow;
  text-transform: uppercase;
  padding: 1em 2em;
  border-radius: 10em;
  background-color: white;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  &--disabled {
    background-color: $light-gray;
    color: white;
    cursor: not-allowed;
  }

  &--dangerColor {
    color: $error-color;
    border: 4px solid $error-color;
  }

  &--secondaryColor {
    color: white;
    background-color: $secondary-color;
  }
}
