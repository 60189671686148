@import '../../../styles/variables';

.CampaignCreationUsers {
  .section__actions {
    .Button {
      background-color: $accent-color;
      color: white;
      margin-top: 5em;

      &--disabled {
        background-color: $light-gray;
        color: white;
        cursor: not-allowed;
      }
    }
  }

  &__add {

    &FromList {
      display: flex;
      align-items: center;
      width: 40%;

    }

    &FromUsers {
      display: flex;
      align-items: center;

      width: 45%;

      .InputWithLabel {
        margin-right: 1em;
        margin-bottom: 0 !important;
      }
    }


    .Button {
      height: 2.5em;
    }

    .Button:not(.Button--disabled) {
      background-color: $secondary-color;
      color: white;
    }

    &Or {
      margin: 1.5em 0;
      color: #7f7f7f;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
    }
  }

  &__list {
    margin-top: 3em;

    &Title {
      color: #7f7f7f;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 0.5em;

      &Save {
        display: flex;
        align-items: center;
        color: unset;
        font-weight: normal;
        text-transform: none;
        font-size: 0.8em;

        input[type='checkbox'] {
          height: 0.75em;
        }

        .InputWithLabel {
          margin-left: 1em;
          margin-top: 0;
          max-width: 15em;
          font-size: 0.8em;
        }
      }
    }

    li {
      display: flex;
      align-items: center;

      i {
        margin-right: 1em;
        cursor: pointer;
        color: $error-color;
      }
    }
  }

  .basic-multi-select {
    width: 100%;
  }
}

.formHeader {
  display: flex;
  flex-direction: column;
}

.formHeader__title {
  color: #7f7f7f;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0.5em;
}

.formHeader__body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formBody {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
}

.formBody__title {
  color: #7f7f7f;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0.5em;
}

.formBody__body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.userAddButton {
  border-radius: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3em;
  width: 3em;
  background-color: #62a5e2;

  &:hover {
    cursor: pointer;
    background-color: #538cc1;

  }
}