@import '../../../styles/variables';

.CampaignCreationRecap {
  .Button {
    margin-top: 1em;
    background-color: $accent-color;
    color: white;
  }

  &__title {
    color: #7f7f7f;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0.5em;
    margin-top: 2em;

    &:first-child {
      margin-top: 0;
    }
  }

  .section__actions {
    margin-top: 2em;
  }

  .modalContent {
    padding: 3em 5em;
    display: flex;
    flex-direction: column;
    height: 100%;

    .modalTitle {
      font-size: 1.5em;
      font-weight: bold;
      align-self: center;
      justify-self: center;
      margin-bottom: 1em;
    }

    .modalSubscribed {
      height: 300px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      font-size: 1em;
      align-items: center;
      justify-content: center;
      margin-bottom: 1em;
    }

    .buttonTitle {
      align-self: center;
      margin-bottom: 10px;
      font-size: 1em;
      font-weight: bold;
      align-self: center;
      justify-self: center;
      margin-bottom: 1em;
    }

    .buttonsDiv {
      display: flex;
      justify-content: center;
      gap: 1em;
    }

    .okButton {
      box-shadow: 0px 2px 20px rgb(0 0 0 / 20%);
      text-transform: uppercase;
      padding: 1em 2em;
      border-radius: 10em;
      background-color: #23d0ad;
      font-weight: bold;
      cursor: pointer;
      color: white;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0px;
      width: 100px;
    }

    .deleteButton {
      box-shadow: 0px 2px 20px rgb(0 0 0 / 20%);
      text-transform: uppercase;
      padding: 1em 2em;
      border-radius: 10em;
      background-color: #d53a3a;
      font-weight: bold;
      cursor: pointer;
      color: white;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0px;
      width: 100px;
    }

  }
}
