@import '../../styles/variables';

.CampaignDetails {
  padding: 3em 0 3em 0;
  background: $dark-gray-background;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 2em;

  &__header {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 0 0 10px 10px;
    margin-bottom: 2em;

    &Meteo {
      position: relative;
      background-position: center;
      background-size: cover;
      height: 6.5em;
      color: white;
      padding: 1.25em;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        color: #eac618;
        text-transform: uppercase;
        font-weight: bold;
      }

      &>div {
        height: 100%;
      }

      &Icon {
        height: 4em;
        position: absolute;
        top: 1.25em;
        right: calc(1.25em + 12em / 2);
        transform: translateX(50%);

        &Background {
          height: 12em;
          width: 12em;
          border-radius: 50%;
          background: white;
          opacity: 0.1;
          position: absolute;
          top: 0;
          transform: translateY(-50%);
          right: 1.25em;
        }
      }
    }

    &Regularity {
      margin-bottom: 0.75em;
    }

    &Date {
      font-size: 0.75em;
    }

    &Infos {
      padding: 1.25em;

      &Top,
      &Bottom {
        display: flex;
        justify-content: space-between;
      }

      &Top {
        align-items: flex-end;
        margin-bottom: 2em;

        &Right {
          padding-bottom: 1em;
        }
      }

      &Bottom {
        &Left {
          display: flex;
          align-items: center;
          flex-grow: 1;
        }

        &Countdown {
          color: #7d1c72;
          font-size: 2.5em;
          font-weight: bold;
          margin-right: 0.75em;
        }

        &Progress {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          flex-shrink: 1;
          max-width: 25em;
          margin-right: 2em;

          &Dates {
            display: flex;
            justify-content: space-between;
          }

          &Elapsed {
            color: #8f8f8f;
          }

          &Dates,
          &Elapsed {
            font-size: 0.75em;
          }

          .ProgressBar {
            height: 0.75em;
            margin: 0.25em 0;
          }
        }
      }

      .Button {
        border-radius: 4px;
        font-size: 0.75em;
        color: white;
        background: linear-gradient(to left, #ad1547, #7a1c75);
      }
    }

    &User {
      color: #8f8f8f;
    }

    &Title {
      display: flex;
      align-items: center;
      font-size: 2em;
      margin-bottom: 1em;

      i {
        margin-left: 0.5em;
      }
    }
  }

  &__stats {
    display: flex;
    margin-bottom: 5em;

    &Card {
      flex-basis: 0;
      display: flex;
      align-items: center;
      flex-grow: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      box-shadow: $shadow;
      padding: 2em;
      background: white;
      border-radius: 10px;
      margin-right: 2em;

      &:last-child {
        margin-right: 0;
      }

      &--main {
        background: $accent-color;
        color: white;

        .CampaignDetails__statsCardTitle {
          color: white;
        }
      }

      &Title {
        font-size: 2em;
        font-weight: bold;
        color: $accent-color;

        span {
          font-size: 0.75em;
        }
      }
    }
  }

  &__separator {
    color: #8f8f8f;
    text-transform: uppercase;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 0 2em;
    margin-bottom: 3em;

    &Bar {
      height: 1px;
      margin-left: 1em;
      flex-grow: 1;
      background-color: #c7c7c7;
    }
  }

  &__details {
    display: flex;
    flex-wrap: wrap;

    &Card {
      height: 25em;
      width: calc(50% - 1em);
      border-radius: 10px;
      background-color: white;
      position: relative;
      margin-right: 2em;
      margin-bottom: 2em;
      box-shadow: 0px 2.5px 5px rgba(0, 0, 0, 0.1);
      padding: 2em;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      &:hover {
        box-shadow: $shadow;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }

      @media (max-width: 1400px) {
        width: 100%;
        margin: 0;
        margin-bottom: 2em;
      }

      @media (min-width: 1850px) {
        width: calc(100% / 3 - 4em / 3);

        &:nth-child(3n) {
          margin-right: 0;
        }

        &:nth-child(2n) {
          margin-right: 2em;
        }
      }

      &Title {
        text-transform: uppercase;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #8f8f8f;
      }

      &Subtitle {
        color: $dark-gray;
        margin-bottom: 1em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &Body {
        display: flex;
        align-items: center;
        flex-grow: 1;
        justify-content: center;

        &Tag {
          position: absolute;
          top: 0;
          right: 2em;
          background: #faba39;
          color: white;
          display: flex;
          border-radius: 0 0 5px 5px;
          max-width: 15em;
          font-size: 0.9em;

          &Entry {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 1em 0.75em;
            text-transform: uppercase;
            font-size: 0.8em;
            max-width: 10em;

            &Value {
              font-size: 2em;
              font-weight: bold;
            }
          }
        }

        &Chart {
          display: flex;
          justify-content: center;
          max-width: 66%;
          flex-shrink: 1;
          margin-right: 2em;
          align-self: center;

          &--bar {
            max-width: 100%;
          }
        }

        &Legend {
          &Entry {
            display: flex;
            align-items: center;
            margin-bottom: 0.5em;

            &:last-child {
              margin-bottom: 0;
            }

            &Color {
              width: 1em;
              height: 1em;
              border-radius: 50%;
              margin-right: 1em;
              flex-shrink: 0;
            }
          }
        }

        &--chart {
          width: 100%;
          margin-top: 2em;
        }
      }
    }
  }
}

.emailButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8em;
  height: 3em;
  box-shadow: 0px 2.5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 0.5em;


  &:hover {
    box-shadow: $shadow;
  }
}