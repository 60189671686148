@import './styles/variables';

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  color: $primary-color;
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'Futura', 'Lato', Arial, Helvetica, sans-serif;
}

i {
  font-family: 'Material Icons';
  text-decoration: none;
  font-style: normal;
  user-select: none;
}

#app {
  height: 100%;
}

@font-face {
  font-family: 'Futura';
  src: url('../assets/fonts/Futura-Medium.woff2') format('woff2'),
    url('../assets/fonts/Futura-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
