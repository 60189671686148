@import '../../../styles/variables';

.CampaignCreationTemplate {
  .Button {
    margin-top: 1em;
    background-color: $accent-color;
    color: white;

    &--disabled {
      background-color: $light-gray;
      color: white;
      cursor: not-allowed;
    }
  }

  &__certification {
    &Period {
      display: flex;
      width: 100%;
      align-items: flex-end;
      margin-bottom: 1em;

      span {
        flex-shrink: 0;
        margin: 0 1em;
        transform: translateY(calc(-1.625em / 2));
      }

      .InputWithLabel {
        margin-bottom: 0 !important;
      }
    }
  }
}
