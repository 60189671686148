@import "../../styles/variables";

.layout2 {
    padding: 20px;
    background-color: #dedede;
    // position: absolute;
}

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.gamifiedHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 200px;
    border-radius: 20px;
    box-shadow: 0px 2.5px 5px rgba(0, 0, 0, 0.2);
    font-size: 60px;
    background-color: white;
}

.informationsTab {
    width: 100%;
    min-height: 65%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2.5px 5px rgba(0, 0, 0, 0.2);
}

.userInformationsTable {
    border-radius: 20px;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    table {
        width: 100%;
    }

    th,
    td {
        text-align: left;
        padding: 16px;
    }

    thead {
        background-color: white;
        border-bottom: 1px solid #C7C7C7;
    }

    tr:nth-child(odd) {
        background-color: white;
    }

    tr:nth-child(even) {
        background-color: $dark-gray-background;
    }
}

.tableHeader {
    height: 100px;
    background-color: #ED931B;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
    // justify-content: center;
    align-items: center;


    h1 {
        color: white;
        font-size: 30px;
        font-weight: 550;
    }

    h1:first-child {
        margin-left: 43px;

        &:hover {
            cursor: pointer;
        }
    }

    h1:nth-child(2) {
        margin-left: 10px;
    }

    &__downloadCSVButton {
        margin-left: auto;
        margin-right: 45px;
        width: 220px;
        height: 57px;
        background: #FFFFFF;
        border: 1px solid #C4C4C4;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        display: flex;
        align-items: center;

        &:hover {
            cursor: pointer;
        }
    }

    &__downloadCSVButton>svg {
        margin-left: 14.51px;
    }

    &__downloadCSVButton>span {
        margin-left: auto;
        margin-right: 35px;
        font-family: 'Futura';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
    }
}

.returnBlock {
    height: 100%;
    width: 15em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.returnBlock__Button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.8em;
    height: 60%;
    width: 90%;
    background-color: rgb(255, 255, 255);
    font-size: 24px;
    box-shadow: 0px 8px 5px rgba(0, 0, 0, 0.1);

    &:hover {
        cursor: pointer;
        box-shadow: $shadow;

    }
}