.Layout {
  $menu-width: 15rem;

  height: 100%;
  display: flex;

  .Layout__menu {
    width: $menu-width;
  }

  &__content {
    margin-left: $menu-width;
    height: 100%;
    width: calc(100% - #{$menu-width});

    &--full {
      margin-left: 0;
      width: 100%;
    }
  }

  @media (max-width: 1000px) {
    $mobile-menu-height: 5em;

    flex-direction: column;

    .Layout__menuMobile {
      width: 100%;
    }

    &__content {
      margin-left: 0;
      margin-top: $mobile-menu-height;
      width: 100%;
      height: calc(100% - #{$mobile-menu-height});

      &--full {
        margin-top: 0;
        height: 100%;
      }
    }
  }
}
