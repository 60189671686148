@import "../../styles/variables";

.search-form {
  position: relative;
  width: 250px;
  height: 40px;
  border-radius: 20px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  background: #fff;
  transition: all 0.3s ease;
}

.search-input {
  position: relative;
  top: 8px;
  left: 15%;
  font-size: 14px;
  font-family: 'Futura';
  background: none;
  width: 195px;
  height: 20px;
  border: none;
  outline: none;
}

.search-icon {
  position: absolute;
  top: 10px;
  left: 15px;
  padding: 0;
  margin: 0;
  border: none;
  outline: none !important;
  cursor: pointer;
  // fill: #5a6674;
  & svg {
    width: 15px;
    height: 15px;
  }
}
