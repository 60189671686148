@import '../../styles/variables';

.UserForm {
  padding: 3em 5em;

  .Button {
    margin-top: 2em;
    color: white;
    background-color: $accent-color;
  }

  .InputWithLabel {
    margin-bottom: 1em;
  }

  &__error {
    color: $error-color;
    margin-bottom: 1em;
    margin-top: -1em;
  }
}
