@import '../../styles/variables';

.Users {
  padding: 5em 3em;
  background: $dark-gray-background;
  min-height: 100%;

  &__actions {
    display: flex;
    margin-bottom: 3em;

    .Button {
      margin-right: 2em;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__body {
    display: flex;

    &Content {
      flex-grow: 1;
      margin-right: 2em;

      &List,
      &Users {
        background: white;
        border-radius: 10px;
        overflow: hidden;
        margin-top: 2em;

        &Header {
          background: $accent-color;
          padding: 1em 2em;
          color: white;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &Title {
            font-size: 1.5em;
            margin-bottom: 0.25em;
          }

          &Recap {
            display: flex;

            &Entry {
              margin-right: 1em;
              display: flex;
              align-items: center;

              &:last-child {
                margin-right: 0;
              }

              &Icon {
                margin-right: 0.25em;
              }
            }
          }
        }

        &Body {
          padding: 2em;

          table {
            border-spacing: 0;
            margin-bottom: 2em;
            width: 100%;

            td,
            th {
              padding: 0.5em 1em;
              text-align: left;
            }

            thead {
              tr {
                th {
                  border-bottom: 1px solid $dark-gray;
                }
              }
            }

            tbody {
              :hover {
                cursor: pointer;
              }
              tr {
                &:nth-child(2n) {
                  background-color: $dark-gray-background;
                }
              }
            }
          }

          &Entry {
            &Remove {
              color: $error-color;
              cursor: pointer;
            }

            &Edit {
              color: $secondary-color;
              cursor: pointer;
            }
          }

          &Add {
            display: flex;
            align-items: flex-end;

            .InputWithLabel {
              max-width: 25em;
              margin-right: 2em;
            }

            .Button {
              height: 2.5em;
            }
          }
        }
      }
    }

    &Recap {
      width: 20em;
      background: white;
      border-radius: 10px;
      padding: 2em;

      &Title {
        font-size: 1.5em;
        margin-bottom: 1em;
      }

      &Entry {
        display: flex;
        justify-content: space-between;
        padding: 1em;
        border-top: 1px solid $light-gray;
        cursor: pointer;

        &:hover {
          .Users__bodyRecapEntryTitle {
            text-decoration: underline;
          }
        }

        &:last-child {
          padding-bottom: 0;
        }

        &Value {
          padding: 0.25em 0.75em;
          border-radius: 1em;
          background-color: $secondary-color;
          color: white;
        }
      }
    }
  }
}
